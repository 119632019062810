@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap");

* {
  font-family: "Barlow", sans-serif !important;
  line-height: 1.563rem;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p,
li {
  /* letter-spacing: 1px; */
  /* font-size: 1.125rem; */
  font-weight: 200;
}

body a {
  text-decoration: none;
}

/* header start */
.headerSocial ul {
  padding: 0;
  list-style-type: none;
}

.hide4 {
  display: none !important ;
}
.show4 {
  display: block;
  width: 100%;
  position: fixed;
}

.headerSocial ul li {
  margin: 0 15px;
  float: left;
}

.headerSocial ul li a {
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.logo {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

/* header end */
/* side buttons start */
.btn2 {
  position: fixed;
  left: -72px;
  top: 340px;
  padding: 45px 0 0px 12px;
  height: 80px;
  width: 130px;
  border-radius: 20px;
  /* background-color: blueviolet; */
  background-color: #e5e5e5;

  transform: rotate(-90deg);
}

.btn3 {
  position: fixed;
  left: -35px;
  top: 445px;
  padding: 3px 0 0 40px;
  height: 40px;
  width: 70px;
  border-radius: 40px;
}

.btn4 {
  position: fixed;
  left: -45px;
  top: 485px;
  padding: 3px 0 0 50px;
  height: 40px;
  width: 80px;
  border-radius: 40px;
}

.btn5 {
  z-index: 1;
  border: none;
  border-radius: 50%;
  position: fixed;
  right: 10px;
  top: 450px;
  height: 70px;
  width: 70px;
}

.form {
  display: none;
  transform: rotate(90deg);
  position: absolute;
  top: 45px;
  right: -40px;
  background-color: white;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px gray, 0px 0px 5px 0px gray;
  border-radius: 10px;
  padding: 5px 15px;
  height: 400px;
  max-width: 350px;
}

.btn2:hover .form {
  display: block;
}

.form input {
  background-color: transparent;
  border: 2px solid black;
  padding: 4px;
  border-radius: 20px;
  height: 40px;
  width: 300px;
}

/* section page start */
.sectionImg {
  text-align: center;
  color: black;
  width: 100%;
  height: 100%;
}

.mainContainer {
  width: 100%;
  height: 80vh;
  border: 1px solid red;
}

.home_image {
  width: 100%;
  height: 100%;
  background-color: red;
  border: 1px solid red;
}

.centered {
  color: whitesmoke;
  position: absolute;
  top: 50%;
  left: 49%;
  /* width: "400px"; */
  transform: translate(-50%, -50%);
  margin: auto;
}

.scroll-text {
  color: whitesmoke;
  position: absolute;
  top: 2%;
  left: 0%;
  width: 100%;
}

.marque_text {
  font-size: 35px;
  color: red;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: black !important;
}

.card-header h6 {
  font-size: 42px;
  line-height: 2;
}
.card-header p {
  font-size: 18px;
  line-height: 1.9;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.section_text {
  font-size: 3.8vw !important;
}

.section_btn {
  border: none;
  border-radius: 10px;
  font-size: 18px;
  height: auto;
  width: auto;
  background-color: #4a4a4a;
}

/*.................. section page start ..................*/
/* ..................side buttons end ..................*/

/* ..................navbar start.................. */

.navbarStyle {
  background-color: #d2d1d1 !important;
  /* margin-bottom: 2px; */
  /* border-bottom: 10px solid red !important; */
}
.nav-shadow {
  box-shadow: 0px 0px 0px 0px rgb(97, 97, 97), 0px 0px 5px 0px #777575;
}

.nav-shadow ul {
  padding: 0px !important;
}

.nav-shadow ul li {
  padding: 2px 0;
  /* color: white; */
  font-size: 18px;
  margin: 5px 0;
  /* width: 100%; */
  line-height: 1.5 !important;
  font-weight: 500;
}

.nav-shadow ul li .colorEffect {
  border-radius: 4px;
  font-size: 1.2rem;
  /* background: linear-gradient(to right, #9971f0 50%, transparent 50%); */
  /* background: linear-gradient(to right, #c9c9c9 50%, transparent 50%); */
  background-size: 220% 150%;
  background-position: right bottom;
  transition: all 0.8s ease-out;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, rgb(215, 39, 4) 50%, transparent 50%);
  /* border-image-slice: 1; */
}

.nav-shadow ul li .colorEffect:hover {
  background-position: left bottom;
}

.nav-shadow ul li ul li a:hover {
  background-color: #ffffff;
}

/*.................. navbar end ..................*/
/*.................. about button know more start..................*/
.Aboutbtn {
  border-radius: 2px;
  text-align: center;
  height: 50px;
  width: 250px;
  border: none;
  background-color: red;
  color: white;
  font-size: 22px;
}

.Aboutbtn:hover {
  background-color: black;
  color: white;
}

/*.................................... why choose H&M start ....................................*/
.sectionRow1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ....................................why choose H&M end ....................................*/
/* ....................................card start....................................*/
.cardStyle .btn {
  background-color: #030303 !important;
  border-color: #6d6d6f !important;
}

.cardStyle {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.cardStyle:hover {
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

/* ...................card .................. */
.btn:hover {
  background-color: rgb(68, 67, 67) !important;
}

/*.................. button ........................ */
.frontlayer {
  animation: shimmerBackground 53s linear infinite;
  background: linear-gradient(
      121.19deg,
      rgba(133, 239, 49, 0) 25.73%,
      rgba(255, 255, 255, 0.3) 45.27%,
      rgba(133, 239, 49, 0) 62.27%
    ),
    #5e5959;
  display: block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 9px;
  font-size: 18px;
  transform: translateY(-4px);
  cursor: pointer;
}

.background {
  padding: 0;
  border: none;
  border-radius: 9px;
}

.frontlayer:hover {
  transform: translateY(0px);
}

@keyframes shimmerBackground {
  0% {
    background-position: -100px 0;
  }

  100% {
    background-position: 100px 0;
  }
}

/* ...end button............ */

/* ...................card end...................*/
/*................... about button know more end...................*/
/*................... hm video start................... */
.video {
  background-size: cover;
  height: 400px;
  width: 100%;
}

/* ...................h&m success start ...................*/

/*................... footer start................... */
.footerSocial ul {
  list-style-type: none;
}

.footerSocial ul li {
  float: left;
}

.footerSocial ul li a {
  font-size: 20px;
  color: white;
  margin: 10px;
  text-decoration: none;
}

.list-unstyled > li > a:hover {
  color: #bebbbb !important;
}

.linkIcon {
  /* border: 1px solid red; */
  display: flex;

  justify-content: space-between;
}
.linkIcon a img {
  height: 25px;
  width: 25px;
  margin: 5px;
}
.linkIcon > a:nth-child(1) > img {
  margin-left: -5px;
}
.bgShow h6 {
  color: white;
}

.bgShow > hr {
  background-color: white;
  
  /* background-color: #bb4e83; */
}
.bgHide h6 {
  color: #aeaaaa;
}

.bgHide > hr {
  background-color: rgb(142, 139, 139) !important;
}
.serviceList {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  width: 40% !important;
  margin: auto;
  justify-content: space-between;
}
.serviceList div {
  /* border: 1px solid red; */
  text-align: left;
  margin: 4px;
}
/*................... footer end ...................*/

/*................... Sample start................... */
.theme_Section {
  margin-top: 30px;
  padding: 20px;
}
.theme_rightContainer header {
  color: red;
}

.theme_leftSection {
  padding: 0;
}

.theme_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.theme_leftSection {
  text-align: center;
}

.themeleftSection_lowerSection {
  display: flex;
  justify-content: space-around;
}

.themeleftSection_lowerSection {
  margin-top: 50px;
  padding: 10px;
}

.themeleftSection_middle {
  text-align: center;
}

.theme_rightSection {
  max-width: 400px;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba(245, 245, 245, 0.87);
}

.themeleftSection_lowerContainer {
  margin-right: 20px;
}

/*................... Sample end ...................*/

/* ...................about page css btn................... */
.contactbtn {
  font-size: 20px;
  height: 50px;
  width: 150px;
  background-color: #212529;
  border: none;
  color: white;
}

.contactbtn:hover {
  background-color: gray;
}

/* about page start */
.aboutSecImg {
  max-width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
}

.contactSec {
  padding-top: 150px;
}

.contactHeadings h2 {
  font-size: 42px;
}

.contactHeadings h5 {
  font-size: 32px;
  color: orange;
}

.contactHeadings h3 {
  font-size: 35px;
}

.contactPara {
  font-size: 22px;
  line-height: 34px;
}

/*................... contact form Styling ...................*/
.formBtn {
  background-color: #dee2e6;
}
.navbar-toggler {
  border: none !important;
}

.formBtn > button {
  height: 55px;
  width: 150px;
  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #dbb8c9;
}

.formBtn > button:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #dbb8c9;
}

.formBtn input,
textarea {
  background-color: #f8f9fa;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 1rem;
  color: #141619;
  box-shadow: 0 0.4rem #dbb8c9;
}

.formBtn input,
textarea:focus {
  outline-color: #bb4e83;
}

/*................... services................... */
/*................... web develope start ...................*/
.effect:hover h4 {
  color: red;
}

.effect:hover {
  position: relative;
  top: -5px;
  -webkit-box-shadow: 0px 0px 18px -7px rgba(54, 17, 89, 0.75);
  -moz-box-shadow: 0px 0px 18px -7px rgba(54, 17, 89, 0.75);
  box-shadow: 0px 0px 18px -7px rgba(54, 17, 89, 0.75);
}

/*................... web develope end................... */
/*................... app develope start ...................*/
.cardShadow {
  position: relative;
  top: 0px;
  width: 350px !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(148, 100, 148, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(148, 100, 148, 1);
  box-shadow: 0px 0px 10px 0px rgba(148, 100, 148, 1);
  transition: top 1s;
}

.cardShadow:hover {
  position: relative;
  top: -10px;
}

.appImg {
  margin: 20px auto;
  height: 100px !important;
  width: 100px !important;
}

/* ...................app develope end ...................*/
/* ...................software develope start................... */
.softwareImg {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.vl {
  border-left: 6px solid rgb(109, 108, 109);
  min-height: 250px;
}

/* ...................software develope end ...................*/
/* ...................digital marketing page start ...................*/
.dgtm_card {
  position: relative;
  top: 0px;
  width: 350px !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  transition: top 1s;
  transition: background-color 1s ease-in-out;
}

.dgtm_card:hover {
  background-color: lightgray;
}

.dgtm_need_card {
  border-radius: 10px;
  min-height: 250px;
  width: auto;
  background-color: whitesmoke;
}

.dgtm_need_card:hover .dgtm_need_img {
  transform: rotate(45deg);
}

.dgtm_choose_hm {
  height: auto;
  background: linear-gradient(rgba(0, 5, 5, 0.8), rgba(8, 5, 0, 0.8)),
    url("/public/assets/image/dgtm/dgtm_choose_img.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.dgtm_choose_card {
  border-radius: 10px;
  min-height: 250px;
  width: auto;
  background-color: lightgray;
}

.dgtm_chhose_img {
  margin: auto;
}

/* ................... digital marketing page end ...................*/
/* ...................automated system page start................... */
.automted_section_img {
  filter: grayscale(80%);
}

.video3 {
  filter: grayscale(100%);
}

/* ...................automted system page end................... */

/* ...................business analysis start...................................... */
.business_card {
  position: relative;
  top: 0px;
  min-height: 350px !important;
  width: 350px !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1),
    0px 0px 10px 0px rgba(128, 128, 128, 1);
  transition: top 1s;
  transition: background-color 1s ease-in-out;
}

.business_card:hover {
  color: white;
  top: -10px;
  background-color: rgb(126, 125, 126);
}

/* business analysis end */
.business_benifits_img {
  height: 200px !important;
  width: 200px !important;
  margin: 20px auto;
}

.businessImg {
  margin: 20px auto;
  height: 100px !important;
  width: 100px !important;
}

/* social media start */
.socialImg {
  height: 60px !important;
  width: 60px !important;
  margin: 20px auto;
}

/* social media end */

/* ........................video style .................... */

.video-container {
  position: relative;
  /* padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); */
  height: 0;
  width: 100%;
  height: 70%;
  background-color: white;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: white;
}

iframe:focus {
  outline: none;
  background-color: none;
}

iframe[seamless] {
  display: block;
}
.nav-shadow ul {
  background-color: #e5e5e5 !important;
}
/* .....................end video style................................ */

/* .....................media quary......................... */

@media only screen and (max-width: 370px) {
  /* ........footer ....... */
  .list-unstyled li a {
    font-size: 15px;
  }
  .list-unstyled li img {
    width: 20px !important;
    height: 20px !important;
  }
  .email {
    margin-right: -70px !important;
  }
}

@media only screen and (max-width: 400px) {
  .visitor{
    font-size: 15px;
  }
  .theme_rightSection {
    width: 300px;
  }

  .space {
    justify-content: none;
  }

  .stable {
    position: fixed;
  }

  /* home Page */
  h1 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  .review_content > h2 {
    font-size: 20px !important;
  }
  .review_content > h3 {
    font-size: 16px !important;
  }
  .review_content > p {
    font-size: 14px !important;
  }
  p {
    font-size: 1rem;
  }
  .nav-shadow ul li .colorEffect {
    p {
      font-size: 1rem;
    }
    .nav-shadow {
      width: 100%;
    }
  }

  .serviceList {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important;
    margin: auto;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 670px) {
  .marque_text {
    font-size: 20px;
  }
  .card-header > h6 {
    font-size: 20px;
  }
  .card-header > p {
    font-size: 12px;
    line-height: 1.6;
  }
  .hide4 {
    display: none;
  }
  .show4 {
    display: block;
    position: fixed;
  }

  .navbarStyle {
    background-color: #d3d0d0 !important;
  }
  .serviceList {
    width: 60%;
    margin: auto;
  }
}
/* Dropdown hover Effect */
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    position: absolute;
  }
  .stable {
    position: fixed;
  }
  .serviceList {
    width: 70%;
    margin: auto;
  }
  .serviceList {
    width: 65%;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .vl {
    display: none !important;
  }
  .contactSec {
    padding-top: 50px;
  }
  .serviceList {
    width: 80% !important;
    margin: auto;
  }
}
@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .themeleftSection_lowerSection {
    margin-top: 20px;
  }

  .theme_Section {
    padding: 10px;
  }

  .space {
    justify-content: space-between;
  }

  .review_content > h2 {
    font-size: 20px !important;
  }
  .review_content > h3 {
    font-size: 16px !important;
  }
  .review_content > p {
    font-size: 14px !important;
  }
  p {
    font-size: 1rem;
  }
  /* .serviceList {
    width: 70% !important;
    margin: auto;
  } */
}

@media only screen and (min-width: 900px) {
  .serviceList {
    width: 70%;
    margin: auto;
  }
}
@media only screen and (min-width: 990px) {
  .review_content > h2 {
    font-size: 20px !important;
  }
  .review_content > h3 {
    font-size: 16px !important;
  }
  .review_content > p {
    font-size: 14px !important;
  }
  p {
    font-size: 1rem;
  }
}
